import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import crypto from '../../config/crypto';
import { GET_DOCUMENT_CATEGORY_LIST, LEADS_HISTORY_QUERY, LEAD_DETAIL_QUERY } from '../../services/leads.gql';
import { getLeadRemarks } from '../../store/action/allAction';
import Loader from '../elements/Loader';
import LeadDetailTabs from './LoanFiledTabs';
import LoanRibbenBox from './LoanRibbenBox';

const LeadDetail = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const client = useApolloClient();
    const [tipOpen, toggleTip] = useState(false);
    const [tipOpen1, toggleTip1] = useState(false);
    const [tipOpen2, toggleTip2] = useState(false);
    const [tipOpen3, toggleTip3] = useState(false);
    const [tipOpen5, toggleTip5] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [documentCategoryList, setDocumentCategoryList] = useState([]);
    const getLeadDetail = (lead_id) => {
        setIsLoading(true);
        // if (!lead_id) lead_id = 0;
        if (!lead_id) return;
        executeGraphQLQuery(LEAD_DETAIL_QUERY(lead_id), client)
            .then(async (response) => {
                if (response && response.data) {
                    let data = response.data.lead_details;
                    if (data) {
                        let payload = data;
                        dispatch({ type: 'GET_LEAD_DETAIL', payload });
                    } else {
                        let payload = {};
                        dispatch({ type: 'GET_LEAD_DETAIL', payload });
                        toast.error('Lead not found');
                    }
                    await docCategoryList();
                    setIsLoading(false);
                } else {
                    if (response?.errors?.length)
                        toast.error(response?.errors?.[0].message, {
                            toastId: 'timeout',
                        });
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                toast.error(error.toString().replace('ApolloError:', ''));
            });
    };

    const getLeadHistory = (lead_id) => {
        // setIsLoading(true);
        if (!lead_id) lead_id = 0;
        executeGraphQLQuery(LEADS_HISTORY_QUERY(lead_id), client)
            .then((result) => {
                if (result?.data?.get_lead_history) {
                    let payload = result.data;
                    dispatch({ type: 'GET_LEAD_HISTORY', payload });
                    // setIsLoading(false);
                } else {
                    let payload = {};
                    dispatch({ type: 'GET_LEAD_HISTORY', payload });
                    // setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.error('Query error:', error);
            });
    };

    const docCategoryList = () => {
        // setIsLoading(true);
        executeGraphQLQuery(GET_DOCUMENT_CATEGORY_LIST(0), client)
            .then((resp) => {
                if (resp?.data?.get_document_list) {
                    setDocumentCategoryList(resp.data);
                    // setIsLoading(false);
                }
            })
            .catch((err) => {
                // setIsLoading(false);
                console.log(err);
            });
    };

    let lead_id = crypto.decode(params.lead_id);
    // useEffect(() => {
    //     docCategoryList();
    // }, [lead_id]);

    useEffect(() => {
        getLeadDetail(parseInt(lead_id));
        getLeadHistory(parseInt(lead_id));
        dispatch(getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client));
    }, [lead_id]);

    //   console.log(documentCategoryList,"Lead-detail page")

    return (
        <div className="container-fluid">
            <LoanRibbenBox
                tipOpen={tipOpen}
                tipOpen1={tipOpen1}
                tipOpen2={tipOpen2}
                tipOpen3={tipOpen3}
                tipOpen5={tipOpen5}
                toggleTip={toggleTip}
                toggleTip1={toggleTip1}
                toggleTip2={toggleTip2}
                toggleTip3={toggleTip3}
                toggleTip5={toggleTip5}
                getLeadHistory={getLeadHistory}
            />
            {isLoading ? <Loader /> : ''}
            <LeadDetailTabs
                getLeadDetail={getLeadDetail}
                docCategoryList={documentCategoryList}
                toggleTip={toggleTip}
                toggleTip1={toggleTip1}
                toggleTip2={toggleTip2}
                toggleTip3={toggleTip3}
                toggleTip5={toggleTip5}
                getLeadHistory={getLeadHistory}
            />
        </div>
    );
};
export default LeadDetail;
