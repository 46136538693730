import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import crypto from '../../config/crypto';
import { LEADS_HISTORY_QUERY, UPDATE_LEAD_ASSIGNED_TO } from '../../services/leads.gql';
import { GET_USER_LIST } from '../../services/role.gql';
import { getLeadRemarks } from '../../store/action/allAction';
import Loader from '../elements/Loader';
import Modal from '../elements/Modal';
import Pagination from '../elements/pagination';
import LeadHistory from './LeadHistory';
import LeadRemarkHistory from './LeadRemarkHistory';

const LeadListTable = ({ leads, t, isLoading, onPaginate, totalPage, currentPage, sortleadlist, getLeadList, getLeadsInput, paginationInput, orderByInput }) => {
    const [isviewHistory, setIsviewHistory] = useState('');
    const [isviewRemarks, setIsviewRemarks] = useState('');
    const [historyList, setHistoryList] = useState([]);
    const [remarkList] = useState([]);
    const [showhistorymodal, setShowhistorymodal] = useState(false);
    const [showremarkmodal, setShowremarkmodal] = useState(false);
    const [showremarkform] = useState(false);
    const [leadList, setLeadList] = useState([]);
    const client = useApolloClient();
    let { remarksHistory, opsExecutiveList } = useSelector(({ lead, masterdata }) => {
        return {
            remarksHistory: lead.remarksHistory || [],
            opsExecutiveList: masterdata.opsExecutiveList?.map(({ id, label }) => ({ value: id, label })) || [],
        };
    });
    const user_information = useSelector((state) => state.user.user_information);
    const UserInfo = JSON.parse(user_information);
    if (UserInfo?.role_id.length === 1 && UserInfo?.role_id.includes(2)) opsExecutiveList = opsExecutiveList.filter(data => data?.value === UserInfo?.id);
    useEffect(() => {
        const fetchData = async () => {
            await getUserList();
        };
        fetchData();
    }, []);

    useEffect(() => {
        setLeadList(leads);
    }, [leads]);
    useEffect(() => {
        if (remarksHistory.length && isviewRemarks !== '') {
            setIsviewRemarks('');
        }
    }, [remarksHistory, isviewRemarks]);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    const toggleModalBox = (lead) => {
        if (Object.keys(lead).length) {
            setIsviewHistory(lead.id);
            setShowhistorymodal(true);
            executeGraphQLQuery(LEADS_HISTORY_QUERY(lead.id), client)
                .then((result) => {
                    if (result?.data?.get_lead_history) {
                        setIsviewHistory('');
                        setHistoryList(result?.data?.get_lead_history);
                    }
                })
                .catch((error) => {
                    console.error('Query error:', error);
                });
        } else {
            setShowhistorymodal(false);
        }
    };

    const toggleModalBoxremark = (lead) => {
        if (Object.keys(lead).length) {
            setIsviewRemarks(lead.id);
            setShowremarkmodal(true);
            dispatch(getLeadRemarks({ lead_id: lead.id, source: 'WEB' }, client));
            setIsviewRemarks('');
        } else {
            setIsviewRemarks('');
            setShowremarkmodal(false);
        }
    };

    const navigateLeadDetail = (lead_id) => {
        navigate(`/lead-detail/customer-details/basic-details/${crypto.encode(lead_id)}`);
    };

    const getUserList = async () => {
        let user_list = [],
            userlist = [];
        await executeGraphQLQuery(GET_USER_LIST(5, 6), client)
            .then((response) => {
                if (response && response.data && response.data.get_user_list) {
                    user_list = response.data.get_user_list.filter((obj) => obj.status === '1');
                    user_list &&
                        user_list.map((v) => {
                            userlist.push({ value: v.user_id, label: v.name, role_id: v.role_assigned_ids });
                            return v;
                        });
                }
            })
            .catch((error) => { });
    };

    const assignToUser = (e, lead_id) => {
        if (!e.value) return;
        if (!window.confirm('Are you sure want to assign?')) return;
        let index = leadList.findIndex((data) => data.id === +lead_id);
        if (leadList?.[index]?.lead_assigned_to === e?.id) {
            toast.error('Loan ID already assigned to this User.');
            return;
        }
        let variables = {
            api_called_by: 'web',
            user_id: UserInfo?.id || 0,
            role_id: UserInfo?.role_id?.[0] || 0,
            lead_id,
            lead_assigned_to: e?.value,
        };
        executeGraphQLMutation(UPDATE_LEAD_ASSIGNED_TO(), variables, client)
            .then((res) => {
                if (res?.data?.update_lead_assigned_to) {
                    toast.success(res?.data?.update_lead_assigned_to?.message);
                    getLeadList({
                        getLeadsInput,
                        paginationInput,
                        orderByInput
                    });
                } else {
                    toast.error('Error');
                }
            })
            .catch((err) => {
                toast.error(err.toString().replace('ApolloError:', ''));
            });
    };

    return (
        <div>
            <div className="lead-list-data-table">
                <table>
                    <thead>
                        <tr>
                            <th>Apps ID</th>
                            <th>Dealer Name</th>
                            <th>Source</th>
                            <th>Service Type</th>
                            <th>Vehicle Details</th>
                            <th>Customer Name</th>
                            <th>Status</th>
                            <th>TAT</th>
                            <th>Agent Name</th>
                            <th>Ops Executive</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leadList?.map((lead) => {
                            let lead_vehicle_detail = `${lead?.lead_vehicle_detail?.make_name || lead?.lead_vehicle_detail?.other_make_name || ''} ${lead?.lead_vehicle_detail?.model_name || lead?.lead_vehicle_detail?.other_model_name || ''
                                } ${lead?.lead_vehicle_detail?.variant_name || lead?.lead_vehicle_detail?.other_variant_name || ''}`;
                            return (
                                <tr key={lead.id}>
                                    <td>
                                        {' '}
                                        <Link to={`/lead-detail/customer-details/basic-details/${crypto.encode(lead.id)}`}>
                                            {' '}
                                            {lead.id}{' '}
                                        </Link>
                                    </td>
                                    <td>
                                        <ul>
                                            <li>{lead?.dealer_details?.organization || '-'}</li>
                                            <li>{(lead?.dealer_details?.gcd_code && `GCD: ${lead?.dealer_details?.gcd_code}`) || '-'}</li>
                                        </ul>
                                    </td>
                                    <td>{lead?.created_by_source || '-'}</td>
                                    <td>{lead?.service_type?.service_type || '-'}</td>
                                    <td>
                                        <ul>
                                            <li>
                                                {lead_vehicle_detail || '-'}
                                            </li>
                                            <li>
                                                {lead?.lead_vehicle_detail?.plate_number || '-'}
                                            </li>
                                        </ul>
                                    </td>
                                    <td>{lead?.customer?.name || '-'}</td>
                                    <td>{lead?.lead_status?.name || '-'}</td>
                                    <td>
                                        {lead?.tat > 0 && <label>
                                            {lead?.tat + ' Days' || ''}
                                        </label>}<br />
                                        {lead?.is_delayed && <span className='listing-delayed'>
                                            {"Delayed" || ''}
                                        </span>}
                                    </td>
                                    <td>
                                        <ul>
                                            <li>
                                                {lead?.lead_assigned_agent?.agent_name || '-'}
                                            </li>
                                            <li>
                                                {(lead?.lead_assigned_agent?.agent_code && `ID: ${lead?.lead_assigned_agent?.agent_code}`) || '-'}
                                            </li>
                                        </ul>
                                    </td>
                                    <td>
                                        <fieldset className="single-select">
                                            <div
                                                className="material"
                                                title={
                                                    opsExecutiveList.filter(({ value }) => value === lead?.lead_assigned_to)?.[0]
                                                        ?.name || '-'
                                                }
                                            ></div>
                                            <Select
                                                className="react-select-table"
                                                classNamePrefix="react-select-table"
                                                placeholder={'Select Assign To'}
                                                options={opsExecutiveList || []}
                                                name={'lead_assigned_to'}
                                                optionLabel={'name'}
                                                optionValue={'id'}
                                                value={opsExecutiveList.filter(({ value }) => value === lead?.lead_assigned_to)}
                                                onChange={(e) => assignToUser(e, lead.id)}
                                                aria-label="Lead assigned to"
                                            />
                                        </fieldset>
                                    </td>

                                    <td>
                                        <ul className="action-btn">
                                            <li onClick={() => navigateLeadDetail(lead.id)}>
                                                <i className="ic-remove_red_eyevisibility"></i>
                                            </li>
                                            <li
                                                onClick={() => {
                                                    toggleModalBox(lead);
                                                }}
                                            >
                                                <i className="ic-Call-history"></i>
                                                {isviewHistory === lead.id ? <Loader /> : null}
                                            </li>
                                            <li
                                                title="Remarks History"
                                                onClick={() => {
                                                    toggleModalBoxremark(lead);
                                                }}
                                            >
                                                <i className="ic-remark-notepad"></i>

                                                {isviewRemarks === lead.id ? <Loader /> : null}
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>

                <p className="align-center-no-record">
                    {leadList?.length < 1 && 'No record found'}
                </p>
            </div>
            {isLoading ? <Loader /> : null}
            <Pagination
                pageCount={totalPage}
                activePage={currentPage || 1}
                onPaginate={onPaginate}
            />

            {showhistorymodal ? (
                <div className="view-timeline-popup image-timeline-popup">
                    <Modal
                        show={showhistorymodal}
                        handleClose={() => {
                            toggleModalBox({});
                        }}
                    >
                        <div className="modal-header">
                            <h2>{'CUSTOMER TIMELINE'}</h2>
                        </div>
                        <LeadHistory historyList={historyList} isLoading={isLoading} t={t} />
                    </Modal>
                </div>
            ) : (
                ''
            )}

            {showremarkmodal ? (
                <div className="view-timeline-popup image-timeline-popup">
                    <Modal
                        show={showremarkmodal}
                        handleClose={() => {
                            toggleModalBoxremark({});
                        }}
                    >
                        <div className="modal-header">
                            <h2>Remark History</h2>
                        </div>
                        <LeadRemarkHistory
                            remarkList={remarkList}
                            showremarkform={showremarkform}
                            isLoading={isLoading}
                            t={t}
                        />
                    </Modal>
                </div>
            ) : (
                ''
            )}


        </div>
    );
};
export default LeadListTable;
